<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <span><strong>Ayarlar</strong></span>
          </CCardHeader>

          <CCardBody>
            <CRow>
              <CCol class="col-12">
                <CRow
                  class="cursor-pointer mb-3"
                  @click="resultPageCollapsed = !resultPageCollapsed">
                  <CCol>
                    <div class="float-left"><h5>Araç Sonuçları</h5></div>
                    <div class="float-right">
                      <font-awesome-icon
                        :icon="['fas', 'chevron-up']"
                        size="lg"
                        v-if="resultPageCollapsed" />
                      <font-awesome-icon
                        :icon="['fas', 'chevron-down']"
                        size="lg"
                        v-else />
                    </div>
                  </CCol>
                </CRow>

                <CCollapse :show="resultPageCollapsed">
                  <CRow>
                    <CCol class="col-md-3 col-12">
                      <CSelect
                        label="Toplam Araç Yazısı"
                        :value.sync="resultPageCarCountForm.status"
                        :options="totalCarStatusOptions" />
                    </CCol>

                    <CCol class="col-md-3 col-12">
                      <CInput
                        label="Toplam Araç Yazısı"
                        v-model="resultPageCarCountForm.text" />
                      <small
                        >Örn: Toplam {TOTAL} adet araç içerisinden, {VIEW} adet
                        araç listelenmektedir.
                      </small>
                    </CCol>
                    <CCol class="col-md-3 col-12">
                      <CSelect
                        label="Partner Promosyon Kodu"
                        :value.sync="partnerPromoCodeStatusDefault"
                        :options="partnerPromoCodeStatus" />
                    </CCol>

                    <CCol class="col-12 text-right">
                      <CButton color="success" @click="resultPageUpdate"
                        >Kaydet</CButton
                      >
                    </CCol>
                  </CRow>
                </CCollapse>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { parseSync } from "@babel/core";
import axios from "axios";
export default {
  name: "Options",
  data() {
    return {
      resultPageCollapsed: true,
      partnerPromoCodeStatusDefault: false,
      // SELECT OPTİONS
      totalCarStatusOptions: [
        {
          value: true,
          label: "Göster",
        },
        {
          value: false,
          label: "Gösterme",
        },
      ],
      partnerPromoCodeStatus: [
        {
          value: true,
          label: "Aktif",
        },
        {
          value: false,
          label: "Pasif",
        },
      ],
      resultPageCarCountForm: {
        status: true,
        text: "",
      },
    };
  },
  methods: {
    resultPageUpdate() {
      axios
        .put(
          process.env.VUE_APP_API_URL + "admin/settings",
          {
            resultPageCarCount: this.resultPageCarCountForm,
            partnerPromoCodeStatus: this.partnerPromoCodeStatusDefault,
          },
          { withCredentials: true }
        )
        .then((response) => {});
    },
  },
};
</script>

<style scoped></style>
